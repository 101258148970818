import ServicesFeatures from "../../../../src/components/Services_features/index.js";
import Container from "../../../../src/components/Services_features/container.js";
import { TwoColumn, Row, Logo, ModuleText } from "../../../../src/bits/twoColumns.js";
import * as React from 'react';
export default {
  ServicesFeatures,
  Container,
  TwoColumn,
  Row,
  Logo,
  ModuleText,
  React
};