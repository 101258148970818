import React from "react";
import styled from "styled-components";

const TwoColumn = styled.div`
  -ms-flex: 50%; /* IE10 */
  flex: 50%;
  max-width: 50%;
  padding: 15px;
  font-size:15px;
  margin-bottom: 15px;
  border-bottom: 0.2px solid #ededed;



  @media screen and (max-width: 800px) {
    -ms-flex: 50%;
    flex: 50%;
    max-width: 50%;
  }
  @media screen and (max-width: 600px) {
    -ms-flex: 100%;
    flex: 100%;
    max-width: 100%;
  }
`

const Row = styled.section`
  display: -ms-flexbox; /* IE10 */
  display: flex;
  -ms-flex-wrap: wrap; /* IE10 */
  flex-wrap: wrap;
`

const Logo = styled.img`
  height: 100px;
  width: 100px;
  
`
const ModuleText = styled.p`
  margin-top:0px;
`
export {TwoColumn, Row, Logo, ModuleText};
