import ServicesFeatures from "../../../../src/components/Services_features/index.js";
import Container from "../../../../src/components/Services_features/container.js";
import { Row, TwoColumn, Logo, ModuleText } from "../../../../src/bits/twoColumns.js";
import DownloadLink from "../../../../src/bits/downloadLink";
import CP86OnePager from "../../../../src/static/CP86 one pager.pdf";
import CSSFOnePager from "../../../../src/static/CSSF Lux one pager.pdf";
import * as React from 'react';
export default {
  ServicesFeatures,
  Container,
  Row,
  TwoColumn,
  Logo,
  ModuleText,
  DownloadLink,
  CP86OnePager,
  CSSFOnePager,
  React
};