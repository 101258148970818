import React from "react"
import styled from "styled-components"

const Features = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 250px;
  padding: 25px;
  transition: all 0.4s ease;
  background: rgba(255, 255, 255, 0.4);
  border-radius: 25px;
  //background: linear-gradient(325deg, #060b1f 0%, #006cff 80%);
  transform: scale(0.95, 0.95);
  box-shadow: 0 10px 25px -20px rgba(0, 0, 0, 0.9);
  margin: 15px 10px 15px 10px;
  word-wrap: break-word;
  @media only screen and (max-width: 425px) {
    margin-top: 10;
    margin-bottom: 10;
    margin-left: 0;
    margin-right: 0;
    transform: translate3d(0px, 0px, 0px);
  }

  @media only screen and (max-width: 1024px) {
    margin: 10 10 10 10;
    transform: translate3d(0px, 0px, 0px);
  }

  &:hover {
    transform: translate3d(2px, 2px, 2px);
  }
`

const Title = styled.div`
  font-weight: bold;
  font-size: 26px;
  text-align: center;
  margin-bottom: 10px;
`

const Description = styled.div`
  //text-align: justify;
  text-align: center;
`

const Logo = styled.img`
  height: 100px;
  width: 100px;
  margin-bottom: 20px;
`

const ServicesFeatures = props => {
  return (
    <Features>
      <Logo src={props.url} />
      <Title>{props.title}</Title>
      <Description>
        <p>{props.description}</p>
      </Description>
    </Features>
  )
}

export default ServicesFeatures
