import React from "react"
import styled from "styled-components"

const Download = styled.a`
  //border-bottom: 1.5px dashed var(--color-grey-500);
  background: linear-gradient(
      to bottom,
      transparent 62%,
      var(--color-accent-medium) 0
    )
    left center/0% 75% no-repeat;
  color: inherit;
  text-decoration: none;
  cursor: pointer;
`

const DownloadLink = ({
  children,
  to,
  activeClassName,
  partiallyActive,
  ...other
}) => {
  return (
    <Download href={to} {...other}>
      {children}
    </Download>
  )
}

export default DownloadLink
