import React from "react"
import styled from "styled-components"

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  flex-direction: row;

  @media only screen and (max-width: 1024px) {
    justify-content: center;
  }
`

const Container = ({ children }) => {
  return <Wrapper>{children}</Wrapper>
}

export default Container
